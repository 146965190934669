import React, {useContext} from "react"
import { ContentText, ContentTextBold } from "../constants/MyFonts";
import {styled} from '@mui/material'

import SectionsTemplate from "./sections/sectionsTemplate";
import { MyColors } from "../constants/MyColors";
import { AppContext } from "../store/context/app-context";

function AboutMe(props) {
    const { 
        theme,
    } = useContext(AppContext);

    const ImageContainer = styled('img')({
        // width: 50, 
        height: 50, 
        objectFit: 'cover',
        borderRadius: 100,
        margin: 8
    })

    const ImageContainerAboutme = styled('img')({
        width: 350, 
        objectFit: 'cover',
        margin: 8
    })

    const content = <div style={{display: 'flex', flex: 1, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} id='aboutme' >
    <ImageContainerAboutme src={'../images/imageaboutme.png'} />
    <ContentText style={{flex: 2, color: theme.text}}>
        <ContentTextBold style={{fontSize: 24, color: MyColors.primaryColors.main}}>
            With over 10 years experience in
        </ContentTextBold>
        <ContentText style={{fontSize: 18, color: MyColors.primaryColors.main}}>
            With over 10 years experience in 
            I am confident that I can help you
            build a modern website tailored to your needs. Take a look at my pertfolio
            although not extensive given this business is quite new the quality speaks for itself.
        </ContentText>
    </ContentText>
    </div>

    return (
        <SectionsTemplate 
            title='' 
            content={content} 
            backgroundColor={theme.background} 
        />
    )
}

export default AboutMe