import React,{useEffect, useState, useLayoutEffect} from "react"
import { motion } from "framer-motion";
import {styled} from '@mui/material'

import ContactInfo from "./banner/contactInfo";
import Title from "./banner/title";
import { Banner_MainText } from "../constants/MyFonts";

function HomepageBanner(props) {
    const ImageContainer = styled('img')({
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    })

    const fixed = props.fixed;

    const [contactSize, setContactSize] = useState('large');
    const [headerHeight, setHeaderHeight] = useState(350);
    const [headerHeightSmall, setHeaderHeightSmall] = useState(180);
    const [isScrolled, setScrolled] = useState(false);

    useEffect(() => {
        if(!fixed) {
            // Adding the scroll listener
            window.addEventListener('scroll', handleScroll, { passive: true });
            return () => {
                // Removing listener
                window.removeEventListener('scroll', handleScroll);
            };
        }
        else {
            setContactSize('small')
        }
    }, []);

    useLayoutEffect(() => {
        function updateBannerHeight() {
            if(window.innerWidth < 1100) {
                setHeaderHeight(700);
                setHeaderHeightSmall(300);
            }
            else {
                setHeaderHeight(350);
                setHeaderHeightSmall(180);
            }
        }
        window.addEventListener('resize', updateBannerHeight);
        updateBannerHeight();
        return () => window.removeEventListener('resize', updateBannerHeight);
      }, []);

    const handleScroll = () => {
        if(window.scrollY >= 150) {
            setScrolled(true);
            setContactSize('small');
        } else {
            setScrolled(false);
            setContactSize('large');
        }
    }

    return (
        <motion.div 
            style={{position: 'sticky', top: 0}}
            initial={!fixed?{ height: headerHeight } : { height: headerHeightSmall }}
            animate={!fixed? isScrolled? { height: headerHeightSmall } : { height: headerHeight } : { height: headerHeightSmall }}
            transition={{ duration: 1 }}
        >
            <ImageContainer src={'../images/background.png'} />
            
            <div style = {{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', position: 'absolute', bottom: 45, left: 10}}>
                
                <Title 
                    size={contactSize} 
                    mainTitle='Mobile / Web Application Experts' 
                    secondaryText = 'We create beautiful mobile & web applications designed to take your business further. Contact us to find out more.'
                />

                <motion.div 
                    style ={{display:'flex', flex: 1, flexDirection: 'row', justifyContent:'flex-start', alignItems: 'center', width: '100%', position: 'absolute', bottom: -15, left: 0}}
                    initial={{ scale: 1, x:0 }}
                    animate={isScrolled? { scale: 0.8, x:-30 } : { scale: 1 }}
                    transition={{ duration: 1 }}
                >
                    <ContactInfo size={contactSize} />
                    {
                        props.title&&
                        <Banner_MainText>{props.title}</Banner_MainText>
                    }
                </motion.div>
                
            </div>
        </motion.div>
    )
}   

export default HomepageBanner