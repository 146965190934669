import React, {useContext} from "react"
import {styled} from '@mui/material'
import { LightMode, DarkMode } from '@mui/icons-material';

import { LightTheme, DarkTheme } from "../../constants/MyColors"
import { ContentText } from "../../constants/MyFonts"
import { AppContext } from "../../store/context/app-context"
import { iconWhite, iconPrimary } from "../../constants/MyStyles";

function ThemeToggle(props) {

    const {
        theme,
        setTheme
    } = useContext(AppContext);

    const Wrapper = styled('div')({
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: 26,
        padding: 4,
        cursor: 'pointer'
    })

    return (
        <Wrapper 
            style={{background: theme.background}}
            onClick={() => {
                setTheme(theme.label === 'light'? DarkTheme : LightTheme)
            }}
        >
            {
                theme.label === 'light'?
                <>
                    <DarkMode sx={iconPrimary} />
                    <ContentText style={{color: theme.text, fontSize: 18}}>Dark</ContentText>
                </>
                :
                <>
                    <LightMode sx={iconWhite} />
                    <ContentText style={{color: theme.text}}>Light</ContentText>
                </>
            }
        </Wrapper>

        
    )
}

export default ThemeToggle