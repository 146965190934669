import React,{useEffect, useContext, useState, useLayoutEffect} from "react"
import {styled} from '@mui/material'
import { AnimatePresence, motion } from "framer-motion"
import { Menu, Close } from '@mui/icons-material';

import { HeadingTextLargeBold} from '../constants/MyFonts'
import {Content, iconPrimary, iconSecondary} from '../constants/MyStyles'
import AppInfoTemplate from "../components/appInfoTemplate";
import { AppContext } from '../store/context/app-context';
import { MyColors } from "../constants/MyColors";
import { iconWhite } from "../constants/MyStyles";
import HomepageBanner from "../components/homepageBanner";
import SkillsAndExp from "../components/skillsAndExp";
import ContactUs from "../components/contactUs";
import AboutMe from "../components/aboutme";
import IconsBar from "../components/IconsBar";

const AppsWrapper = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 8,
    cursor: 'pointer',
    ['@media (max-width:780px)'] :{
        flexWrap: 'wrap',
        flexDirection: 'column',
    },
  })

function Home() {

    const { 
        theme,
        setNavOpen,
        initialAnimated,
        setInitialAnimated
    } = useContext(AppContext);
    
    useEffect(() => {
        setNavOpen(false);
        if(!initialAnimated) {
            // setInitialAnimated(true)
        }
    }, []);

    useLayoutEffect(() => {
        function updateScreenSize() {
            setScreenWidth(window.innerWidth);
            setScreenHeight(window.innerHeight);
        }
      
        window.addEventListener('resize', updateScreenSize);
        updateScreenSize();
        return () => window.removeEventListener('resize', updateScreenSize);
      }, []);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    const [selectedCard, setSelectedCard] = useState(null);

    const CardOpenStyle = {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: screenWidth,
        height: screenHeight - 100,
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 2
    }

    const BackgroundBlur = styled('div')({
        position: 'fixed', 
        top:0, 
        left:0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 1,
        opacity: 1
    })

    const Icon = styled('div')({
        position: 'absolute', 
        top:20, 
        right:20, 
        padding: 8, 
    })

    const cards = [
        {
            id: 1,
            ref: 'meals',
            cardDetails: <AppInfoTemplate cardType='meals' open={false} />
        },
        {
            id: 2,
            ref: 'activity',
            cardDetails: <AppInfoTemplate cardType='activity' open={false} />
        },
        {
            id: 3,
            ref: 'qn',
            cardDetails: <AppInfoTemplate cardType='qn' open={false} />
        },
    ]

    return (
        <>
            <HomepageBanner />
            <AboutMe id="about" />

            <Content id="portfolio" style={{backgroundColor: theme.background}}>
                {
                    selectedCard&&
                        <BackgroundBlur onClick={() => setSelectedCard(null)} />
                }
                
                <HeadingTextLargeBold style={{color: theme.highlightedText}}>Portfolio</HeadingTextLargeBold>
                <AppsWrapper>
                    {
                        cards.map((card)=> (
                            <motion.div 
                                key={card.id}
                                style={{display:'flex', flex: 1, width: '100%'}}
                                layoutId={card.id}
                                transition={{ duration: 0.5 }}
                                onClick={() => {
                                    setSelectedCard(card);
                                }}
                            >
                               {
                                card.cardDetails
                               }
                            </motion.div>
                        ))
                    }
                </AppsWrapper>
            </Content>

            <AnimatePresence>
                {
                    selectedCard&& (
                        <motion.div 
                            style={CardOpenStyle}
                            layoutId={selectedCard.id}
                            initial={{ opacity:1 }}
                            animate={{ opacity: 1}}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <AppInfoTemplate cardType={selectedCard.ref} open = {true} />
                            <Icon 
                                onClick={() => setSelectedCard(null)}>
                                    <Close sx={theme.label === 'light'? iconPrimary : iconWhite} />
                            </Icon>
                        </motion.div>
                    )
                }
            </AnimatePresence>

            <SkillsAndExp id="skills" />

            <IconsBar />
            
            <ContactUs id="contact" />
        </>
    );
  }
  
  export default Home;
  