import React,{useEffect, useContext} from "react"
import {styled} from '@mui/material'

import {ContentText} from '../constants/MyFonts'
import { MyColors } from "../constants/MyColors";
import {Link} from 'react-router-dom'
import { AppContext } from '../store/context/app-context';
import HomepageBanner from "../components/homepageBanner";
import StaticBanner from "../components/staticBanner";

const LinkSectionContainer = styled(Link)({
  textDecoration: 0,
  borderRadius: 4,
  margin: 16,
  backgroundColor: MyColors.primaryColors.secondary,
})

const Content = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  textDecoration: 0,
  borderRadius: 4,
})

function PrivacyPolicy() {

  useEffect(() => {
    document.title = 'Privacy Policy';
  }, []);

  const { 
    theme,
    setNavOpen
} = useContext(AppContext);

  useEffect(() => {
      setNavOpen(false);
  }, []);

  return (
    <div  style={{
      display: 'flex', flex: 1, flexDirection: 'column', height: '100%', minHeight: '100%',
    }}>
      <StaticBanner fixed={true} title='Privacy Policies' />

      <Content style={{backgroundColor: theme.background}}>
          <LinkSectionContainer variant="h6" className="navItem" to = "/privacypolicy/chef">
                <ContentText style={{color: MyColors.colors.black}}>Meal Book - Expert Recipes</ContentText>
          </LinkSectionContainer>

          {/* <LinkSectionContainer variant="h6" className="navItem" to = "/privacypolicy/activitytracker">
                <ContentText style={{color: theme.text}}>Activity Tracker</ContentText>
          </LinkSectionContainer> */}
      </Content>
    </div>
  );
}

export default PrivacyPolicy;
