import React, {useContext} from "react"
import {styled, Typography} from '@mui/material'

import { MyColors } from "../../constants/MyColors";
import { HeadingTextBold, HeadingTextLargeBold } from "../../constants/MyFonts";
import { AppContext } from "../../store/context/app-context";

function SectionsTemplate(props) {

    const {
        theme
    } = useContext(AppContext);

    const backgroundColor = props.backgroundColor? theme.background : MyColors.primaryColors.secondary

    const Wrapper = styled('div')({
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 44,
        paddingBottom: 44,
        // borderBottomWidth: 1,
        // borderBottomStyle: 'solid',
        // borderBottomColor: MyColors.colors.lightGrey,
        backgroundColor: backgroundColor
      })

    return (
        <Wrapper>
            <HeadingTextLargeBold style={{color: theme.highlightedText}}>{props.title}</HeadingTextLargeBold>
            {props.content}
        </Wrapper>
)
}

export default SectionsTemplate