import React, {useContext} from "react"
import { ContentText, ContentTextBold } from "../constants/MyFonts";
import {styled} from '@mui/material'

import SectionsTemplate from "./sections/sectionsTemplate";
import { MyColors } from "../constants/MyColors";
import { AppContext } from "../store/context/app-context";

function SkillsAndExp(props) {
    const { 
        theme,
    } = useContext(AppContext);

    const ImageContainer = styled('img')({
        // width: 50, 
        height: 50, 
        objectFit: 'cover',
        borderRadius: 100,
        margin: 8
    })

    const content = <div id='skills' >
    <ContentText style={{color: theme.text}}>
        <ContentText style={{color: theme.text}}>
            With over 10 years experience in 
            <text style={{color: theme.highlightedText, fontWeight: 'bold'}}> web design </text>
            and 
            <text style={{color: theme.highlightedText, fontWeight: 'bold'}}> UX </text> 
            I am confident that I can help you
            build a modern website tailored to your needs. Take a look at my pertfolio
            although not extensive given this business is quite new the quality speaks for itself.
        </ContentText>

        <ContentTextBold style={{color: theme.text}}>
            A good website starts with a good design that keeps the user journey in mind.
        </ContentTextBold>
        
        <ContentText style={{color: theme.text}}>
            Having worked as a UX designer for a number of years I can help you understand
            your users so you can build a website tailored to their need 
            making your business a success.
        </ContentText>

        <ContentText style={{color: theme.text}}>
            My skills include but are not limited to:
        </ContentText>

        <ContentTextBold style={{color: theme.text}}>
            React (web) & React Native (Mobile Apps), Backend API using nodeJS, 
            Database setup using MongoDB
        </ContentTextBold>

        {/* <ImageContainer src={'../images/reactnative.png'} />
        <ImageContainer src={'../images/mongo.png'} />
        <ImageContainer src={'../images/nodeJS.png'} />
        <ImageContainer src={'../images/css.png'} />
        {
            theme.label === 'light'?
            <ImageContainer src={'../images/uxdark.png'} />
            :
            <ImageContainer src={'../images/ux.png'} />
        }  */}
        
    </ContentText>
    </div>

    return (
        <SectionsTemplate 
            title='Expertise' 
            content={content} 
            backgroundColor={theme.background} 
        />
    )
}

export default SkillsAndExp