import { SubHeadingText, HeadingTextBold, ContentText, ContentTextBold } from "../constants/MyFonts"
import AppInfoBanner from '../components/appInfoBanner'

export const mealsData = {
    banner: '../images/projects/mealsApp/mealsBanner.png',
    pages: [
        {
            page: 0,
            image: '../images/projects/mealsApp/Homepageimage.png',
            text: 'Create sections to add your meals to.'
        },
        {
            page: 1,
            image: '../images/projects/mealsApp/AddMealImage.png',
            text: 'page 2'
        },
        {
            page: 2,
            image: '../images/projects/mealsApp/CalendarImage.png',
            text: 'page 3'
        },
        {
            page: 3,
            image: '../images/projects/mealsApp/ShoppingListImage.png',
            text: 'page 4'
        }
    ]
}

export const activityData = {
    banner: '../images/projects/activitiesApp/activityBanner.png',
    pages: [
        {
            page: 0,
            image: '../images/projects/activitiesApp/webimage1.png',
            text: ''
        },
    ]
}

export const qnData = {
    banner: '../images/projects/qn/qnBanner.png',
    pages: [
        {
            page: 0,
            image: '../images/projects/qn/image1.png',
            text: ''
        },
        {
            page: 1,
            image: '../images/projects/qn/image2.png',
            text: ''
        },
        {
            page: 2,
            image: '../images/projects/qn/image3.png',
            text: ''
        },
        {
            page: 3,
            image: '../images/projects/qn/image4.png',
            text: ''
        },
    ]
}

const ProjectsData = (props) => {
    const appName = props.appName;
    const theme = props.theme;
    const open = props.open

    if(appName === 'meals') {
        return (
            !open?
                <>
                    <HeadingTextBold style={{color: theme.text}}>Meal Book - Expert Recipes</HeadingTextBold>
                    <ContentText style={{color: theme.text}}>Try this exciting new app to help you organise your meals.</ContentText>
                </>
                :
                <>
                    <HeadingTextBold style={{color: theme.text}}>Meal Book - Expert Recipes</HeadingTextBold>

                    <AppInfoBanner appName = 'mealsData' />

                    <>
                        <SubHeadingText style={{color: theme.highlightedText}}>Technologies Used</SubHeadingText>
                        <ContentText style={{color: theme.text}}>Node.js, Express, React Native, CSS</ContentText>
                    </>

                    <>
                        <SubHeadingText style={{color: theme.text}}>Available to Download</SubHeadingText>
                        <ContentText style={{color: theme.text}}>Apple Store, Google Play store</ContentText>
                        <ContentText style={{color: theme.text}}>Comming soon on web</ContentText>
                    </>

                    <>
                        <SubHeadingText style={{color: theme.highlightedText}}>App Information</SubHeadingText>
                        
                        <ContentText style={{color: theme.text}}>Start your cooking journey today!</ContentText>
                        <ContentText style={{color: theme.text}}>
                            We have made it easy for you to add new and existing recipes and plan them into your calendar.
                            We all want a balanced diet to be healthier and to do that we need to organise our meals to make sure we are getting the nutrition we need throughout the week.
                            Meal Book makes it easy for you to save your favourite meals in one place and plan them into your calendar.
                        </ContentText>

                        <ContentTextBold style={{color: theme.text}}>ADD</ContentTextBold>
                        <ContentText style={{color: theme.text}}>Add your favourite meals into the app manually or by using a link from supported websites.</ContentText>
                        <ContentTextBold style={{color: theme.text}}>PLAN</ContentTextBold>
                        <ContentText style={{color: theme.text}}>Plan your favourite meals into your calendar so you don't have to think about what to have for dinner again.</ContentText>
                        <ContentTextBold style={{color: theme.text}}>SHOP</ContentTextBold>
                        <ContentText style={{color: theme.text}}>Conveniently add any items you don't have into your shopping list with one click, all in the same app!</ContentText>
                        <ContentTextBold style={{color: theme.text}}>COOK</ContentTextBold>
                        <ContentText style={{color: theme.text}}>Finally cook your chosen meals and enjoy the health benefits!</ContentText>
                    </>
                </>
        )
    }
    else if(appName === 'activity') {
        return (
            !open?
                <>
                    <HeadingTextBold style={{color: theme.text}}>Activity Tracker</HeadingTextBold>
                    <ContentText style={{color: theme.text}}>End the boredom and enjoy a range of different suggestions to fill your time.</ContentText>
                </>
                :
                <>
                    <HeadingTextBold style={{color: theme.text}}>Meal Book - Expert Recipes</HeadingTextBold>

                    <AppInfoBanner appName = 'activityData' />

                    <>
                        <SubHeadingText style={{color: theme.highlightedText}}>Technologies Used</SubHeadingText>
                        <ContentText style={{color: theme.text}}>React Native, CSS</ContentText>
                    </>

                    <>
                        <SubHeadingText style={{color: theme.text}}>Available to Download Soon</SubHeadingText>
                        <ContentText style={{color: theme.text}}>Apple Store, Google Play store</ContentText>
                    </>

                    <>
                        <SubHeadingText style={{color: theme.highlightedText}}>App Information</SubHeadingText>
                        
                        <ContentText style={{color: theme.text}}>Fill your day with fun activities.</ContentText>
                        <ContentText style={{color: theme.text}}>
                            
                        </ContentText>
                    </>
                </>
        )
    }
    if(appName === 'qn') {
        return (
            !open?
                <>
                    <HeadingTextBold style={{color: theme.text}}>Quran Notes (QN)</HeadingTextBold>
                    <ContentText style={{color: theme.text}}>Elevate you Quran reading and make notes using this easy to use app.</ContentText>
                </>
                :
                <>
                    <HeadingTextBold style={{color: theme.text}}>Quran Notes (QN)</HeadingTextBold>
                    <AppInfoBanner appName = 'qnData' />
                    <>
                        <SubHeadingText style={{color: theme.highlightedText}}>Technologies Used</SubHeadingText>
                        <ContentText style={{color: theme.text}}>React Native, CSS</ContentText>
                    </>

                    <>
                        <SubHeadingText style={{color: theme.text}}>Available to Download</SubHeadingText>
                        <ContentText style={{color: theme.text}}>Apple Store, Google Play store</ContentText>
                        <ContentText style={{color: theme.text}}>Comming soon on web</ContentText>
                    </>

                    <>
                        <SubHeadingText style={{color: theme.highlightedText}}>App Information</SubHeadingText>
                        
                        <ContentText style={{color: theme.text}}>Start your Quran journey today!</ContentText>
                        <ContentText style={{color: theme.text}}>
                        </ContentText>
                    </>
                </>
        )
    }
}

export default ProjectsData