import React from "react"
import {styled} from '@mui/material'
import { MyColors } from "../constants/MyColors";
import { ContentText } from "../constants/MyFonts";
import AppLogo from "./AppLogo";

function Footer(props) {

    const BannerStyled = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
        // backgroundColor: '#02240A',
        backgroundColor: MyColors.primaryColors.main2
      })

    return (
        <BannerStyled>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <AppLogo />
            <ContentText style={{color: MyColors.primaryColors.secondary}}>Copyright 2024. All Rights Reserved</ContentText>
          </div>
        </BannerStyled>
)
}

export default Footer