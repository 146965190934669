import React from "react"
import {styled} from '@mui/material'

import ContactInfo from "./banner/contactInfo";
import Title from "./banner/title";
import { Banner_MainText } from "../constants/MyFonts";

function StaticBanner(props) {
    const ImageContainer = styled('img')({
        width: '100%',
        height: 200,
        objectFit: 'cover',
    })

    return (
        <div style={{position: 'sticky', top: 0, height: 200}}>
            <ImageContainer src={'../images/background.png'} />
            
                <div style ={{display:'flex', flex: 1, flexDirection: 'row', justifyContent:'flex-end', alignItems: 'flex-end', width: '100%', position: 'absolute', bottom: 20, left: 0}}>

                    <ContactInfo size='small' />

                    <div style={{marginRight: 24, marginLeft:24}}>                  
                        {
                            props.title&&
                            <Banner_MainText>{props.title}</Banner_MainText>
                        }
                    </div>  
    
                </div>
        </div>
    )
}   

export default StaticBanner