import React, {useContext} from "react"
import { ContentText, ContentTextBold } from "../constants/MyFonts";
import {styled} from '@mui/material'

import SectionsTemplate from "./sections/sectionsTemplate";
import { MyColors } from "../constants/MyColors";
import { AppContext } from "../store/context/app-context";

function IconsBar(props) {
    const { 
        theme,
    } = useContext(AppContext);

    const ImageContainer = styled('img')({
        // width: 50, 
        height: 60, 
        objectFit: 'cover',
        borderRadius: 100,
        margin: 8
    })

    const content = <div id='' >

        <ImageContainer src={'../images/reactnative.png'} />
        <ImageContainer src={'../images/mongo.png'} />
        <ImageContainer src={'../images/nodeJS.png'} />
        <ImageContainer src={'../images/css.png'} />
        {
            theme.label === 'light'?
            <ImageContainer src={'../images/uxdark.png'} />
            :
            <ImageContainer src={'../images/ux.png'} />
        } 
    </div>

    return (
        <SectionsTemplate 
            title='' 
            content={content} 
        />
    )
}

export default IconsBar