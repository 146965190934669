import { NineMp } from "@mui/icons-material";
import { createContext, useEffect, useState } from "react";
import { LightTheme, DarkTheme } from "../../constants/MyColors";

export const AppContext = createContext();

export const AppProvider = (props) => {

    const [navOpen, setNavOpen] = useState(false);
    const [navHeight, setNavHeight] = useState(40);
    const [initialAnimated, setInitialAnimated] = useState(false);
    const [theme, setTheme] = useState(LightTheme);

    useEffect(() => {
    },[]);

    const changeNavStatus = async(data) => {
        try {
            // const res = await addToShoppingList(data);

            // if(res.status === 'success') {
            //     const temp = [...shoppingList];
            //     temp.push(data);
            //     setShoppingList(temp);

            //     setFeedbackMessage('Item added successfully');
            // }
        }
        catch(err) {
            console.log(err);
            // navigate('/error', {
            //     state: {
            //         name: err.name,
            //         message: err.message,
            //         code: err.code,
            //         statuscode: err.response.status
            //     }
            // });
        }
    }

    return <AppContext.Provider
    value={{
        navOpen,
        setNavOpen,
        changeNavStatus,
        navHeight,
        setNavHeight,
        initialAnimated,
        setInitialAnimated,
        theme,
        setTheme
    }}>
        {props.children}
    </AppContext.Provider>
}