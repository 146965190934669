import React, {useContext, useEffect, useState} from "react"
import {styled} from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { motion } from "framer-motion";

import { AppContext } from "../store/context/app-context"
import { ContentTextBold } from "../constants/MyFonts";
import { iconPrimaryLarge, iconWhiteLarge } from "../constants/MyStyles";
import {mealsData, activityData, qnData} from '../data/projectsData'

function AppInfoBanner(props) {
    const appName = props.appName;
    const [data, setData] = useState([]);
    const [selectedPage, setSelectedPage] = useState({});

    useEffect(() => {
        if(appName === 'mealsData') {
            setSelectedPage(mealsData.pages[0]);
            setData(mealsData.pages);
        }
        else if(appName === 'activityData') {
            setSelectedPage(activityData.pages[0]);
            setData(activityData.pages);
        }
        else if(appName === 'qnData') {
            setSelectedPage(qnData.pages[0]);
            setData(qnData.pages);
        }
    },[])

    const {
        theme,
     } = useContext(AppContext);

     const Wrapper = styled('div')({
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        // backgroundColor: 'pink',
        // borderWidth: 1,
        // borderStyle: 'solid',
        // borderColor: 'white',
        // borderRadius: 4
    })

    const ImageWrapper = styled('div')({
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        // backgroundColor: 'yellow'
    })
    const DotsWrapper = styled('div')({
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    })

    const ImageContainer = styled('div')({
        diaply: 'flex',
        flex: 1,
        justifyContent: 'center', 
        alignItems: 'center'
        // flexDirection: 'row',
        // flexWrap: 'wrap',
        // width: '100%', 
    })

    const ImageStyle = styled('img')({
        width: 300, 
        // height: '50%', 
        // objectFit: 'cover',
    })

    const DotStyleActive = styled('div')({
        backgroundColor: theme.dotActive,
        padding: 5,
        borderRadius: 200,
        margin: 8
    })

    const DotStyle = styled('div')({
        backgroundColor: theme.dot,
        padding: 5,
        borderRadius: 200,
        margin: 8
    })

    const IconStyle = styled('div')({
        cursor: 'pointer'
    })

    const Dot = (props) => {
        const status = props.status;

        return (
            status?
                <DotStyleActive />
            :
                <DotStyle />
        )
    }

    const changePageToLeft = () => {
        if(appName === 'mealsData') {
            const pageNum = selectedPage.page -1 ;
            if(pageNum >= 0) {
                setSelectedPage(mealsData.pages[pageNum]);
            }
        }
        else if(appName === 'activityData') {
            const pageNum = selectedPage.page -1 ;
            if(pageNum >= 0) {
                setSelectedPage(activityData.pages[pageNum]);
            }
        }
        else if(appName === 'qnData') {
            const pageNum = selectedPage.page -1 ;
            if(pageNum >= 0) {
                setSelectedPage(qnData.pages[pageNum]);
            }
        }
    }

    const changePageToRight = () => {
        if(appName === 'mealsData') {
            const pageNum = selectedPage.page +1 ;
            if(pageNum < mealsData.pages.length) {
                setSelectedPage(mealsData.pages[pageNum]);
            }
        }
        else if(appName === 'activityData') {
            const pageNum = selectedPage.page +1 ;
            if(pageNum < activityData.pages.length) {
                setSelectedPage(activityData.pages[pageNum]);
            }
        }
        else if(appName === 'qnData') {
            const pageNum = selectedPage.page +1 ;
            if(pageNum < qnData.pages.length) {
                setSelectedPage(qnData.pages[pageNum]);
            }
        }
    }

    return (
        <Wrapper>
            <ImageWrapper>
                <IconStyle onClick={changePageToLeft}>
                    {
                        theme.label === 'light'?
                        <KeyboardArrowLeft sx={iconPrimaryLarge} />
                        :
                        <KeyboardArrowLeft sx={iconWhiteLarge} />
                    }
                </IconStyle>
                
                <motion.div
                    initial={{ opacity: 0, y: 0}}
                    animate={{ opacity: 1, y: 10}}
                    transition={{ duration: 1 }}
                >
                    <ImageContainer>
                        <ImageStyle src={selectedPage.image} />
                        <ContentTextBold style={{color: theme.text, textAlign: 'center'}}>{selectedPage.text}</ContentTextBold>
                    </ImageContainer>
                </motion.div>
                
                <IconStyle onClick={changePageToRight}>
                    {
                        theme.label === 'light'?
                        <KeyboardArrowRight sx={iconPrimaryLarge} />
                        :
                        <KeyboardArrowRight sx={iconWhiteLarge} />
                    }
                </IconStyle>
            </ImageWrapper>
            <DotsWrapper>
                {
                    data.map(item => {
                        return(
                            <Dot status = {item.page === selectedPage.page? true : false} />
                        )
                    })
                }
            </DotsWrapper>
        </Wrapper>
    )
}

export default AppInfoBanner